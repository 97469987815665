import { breakpoints } from '../utils/breakpoints'

const stickyCtas = document.querySelectorAll('.sticky-cta')

stickyCtas.forEach(sticky => {
	const items = sticky.querySelectorAll('.sticky-cta__item')

	items.forEach(item => {
		item.addEventListener('click', e => {
			if(window.innerWidth < breakpoints.medium) {
				if(!sticky.classList.contains('sticky-cta--open')) {
					e.preventDefault()

					sticky.classList.add('sticky-cta--open')
				}
			}
		})
	})
})

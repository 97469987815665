const mapElementSelector = '.map__object';
const mapElements = document.querySelectorAll(mapElementSelector);

if (mapElements.length > 0) {
    mapElements.forEach(mapElement => {
        const mapLat = parseFloat(mapElement.dataset.lat);
        const mapLng = parseFloat(mapElement.dataset.lng);
        const mapZoom = parseFloat(mapElement.dataset.zoom) ? parseFloat(mapElement.dataset.zoom) : null;
        const markersData = JSON.parse(mapElement.dataset.markers);
        const markerStyle = mapElement.dataset.markerStyle;
        const iconBase = '/assets/img/maps/';
        const icons = {
            default: {
                icon: iconBase + 'marker.svg'
            },
            active: {
                icon: iconBase + 'marker-active.svg'
            },
            numbered: {
                icon: iconBase + 'marker-plain.svg'
            }
        }
        let markers = []

        const map = new google.maps.Map(mapElement, {
            center: {
                lat: mapLat,
                lng: mapLng
            },
            zoom: mapZoom ? mapZoom : 8
        });

        if (markersData) {
            const markerInfowindow = new google.maps.InfoWindow;

            markersData.forEach((m, i) => {
                const markerIcon = {
                    url: markerStyle === 'numbered' ? icons['numbered'].icon : icons['default'].icon,
                    origin: new google.maps.Point(0, 0),
                    labelOrigin: new google.maps.Point(20, 25),
                    scaledSize: new google.maps.Size(40, 58)
                };

                const markerLabel = {
                    text: `${i + 1}`,
                    color: '#fff',
                    fontSize: '16px',
                    fontWeight: 'bold'
                };

                /* Wanted to use this code but not supported in IE11
                const featureAllotedTime = m.features.find((e) => { return e.FeatureName === 'alloted_time'; });
                const featureCostPerMinute = m.features.find((e) => { return e.FeatureName === 'cost_per_minute'; });
                const featureOverallCost = m.features.find((e) => { return e.FeatureName === 'overall_cost'; });
                const featureSeatingCapacity = m.features.find((e) => { return e.FeatureName === 'seating_capacity'; });
                const featureOutOfHours = m.features.find((e) => { return e.FeatureName === 'out_of_hours'; });
                const featureAudioVisual = m.features.find((e) => { return e.FeatureName === 'audio_visual'; });
                */
                const featureAllotedTime = (function () { for (i = 0; i < m.features.length; i++) { if (m.features[i].FeatureName === 'alloted_time') return m.features[i]; } })();
                const featureCostPerMinute = (function () { for (i = 0; i < m.features.length; i++) { if (m.features[i].FeatureName === 'cost_per_minute') return m.features[i]; } })();
                const featureOverallCost = (function () { for (i = 0; i < m.features.length; i++) { if (m.features[i].FeatureName === 'overall_cost') return m.features[i]; } })();
                const featureSeatingCapacity = (function () { for (i = 0; i < m.features.length; i++) { if (m.features[i].FeatureName === 'seating_capacity') return m.features[i]; } })();
                const featureOutOfHours = (function () { for (i = 0; i < m.features.length; i++) { if (m.features[i].FeatureName === 'out_of_hours') return m.features[i]; } })();
                const featureAudioVisual = (function () { for (i = 0; i < m.features.length; i++) { if (m.features[i].FeatureName === 'audio_visual') return m.features[i]; } })();

                const markerContent = `
					<div class="map-card u-size-100 ${m.image ? '' : 'u-padding-none'}">
						${m.image ? '<div class="map-card__header"  style="background-image: url(' + m.image + ');"></div>' : ''}
						<div class="map-card__content ">
                            ${m.distance ? '<p class="map-card__suptitle">' + m.distance + ' Miles Away</p>' : ''}
                            ${m.title ? '<p class="map-card__title">' + m.title + ' </p>' : ''}
							${m.address ? '<p class="map-card__poi"><i class="icon-poi"></i> <span>' + m.address + '</span></p>' : ''}
                            ${m.telephone ? '<p class="map-card__phone u-padding-bottom--small"> <a href="tel:"><i class="icon-phone"></i> <span>' + m.telephone + '</span></a></p>' : ''}
                            ${m.website ? '<p class="map-card__website"> <i class="icon-internet"></i> <a href="' + m.ctaLink2 + ' target="' + m.ctaTarget1 + '"><span>View Crematorium</span></a></p>' : ''}
${m.features ? `<hr>                            
<div class="grid grid__col--fixed-100 u-margin-bottom--none">

                                                        <div class="card-grid u-align-center u-margin-bottom--none">
                                                            <div class="content-card u-align-center  u-padding-none">
                                                                <div class="content-card__content u-padding-none">
                                                                    <i class="${featureAllotedTime.FeatureIcon} u-color-shade-light u-text-medium"></i>
                                                                    <br />
                                                                    <p class="u-text-smaller u-text-bold u-color-shade-light u-margin-bottom--none">
                                                                        ${featureAllotedTime.FeatureTitle.toUpperCase()}
                                                                        <a href="#${featureAllotedTime.FeatureName}-modal" class="button--plain-text" data-lity><i class="u-color-brand-b u-text-small icon-info-outline"></i></a>
                                                                    </p>
                                                                    <p class="u-color-brand-a u-text-largest u-text-bold">${featureAllotedTime.FeatureValue}<span class="u-text-small">mins</span></p>
                                                                </div>
                                                            </div>


                                                            <div class="content-card u-align-center  u-padding-none">
                                                                <div class="content-card__content u-padding-none">
                                                                    <i class="${featureCostPerMinute.FeatureIcon} u-color-shade-light u-text-medium"></i>
                                                                    <br />
                                                                    <p class="u-text-smaller u-text-bold u-color-shade-light u-margin-bottom--none">
                                                                        ${featureCostPerMinute.FeatureTitle.toUpperCase()}
                                                                        <a href="#${featureCostPerMinute.FeatureName}-modal" class="button--plain-text" data-lity><i class="u-color-brand-b u-text-small icon-info-outline"></i></a>
                                                                    </p>
                                                                    <p class="u-color-brand-a u-text-largest u-text-bold"><span class="u-text-small">&#163;</span>${Number(featureCostPerMinute.FeatureValue).toFixed(2).split(".")[0]}<span class="u-text-small">.${Number(featureCostPerMinute.FeatureValue).toFixed(2).split(".")[1]}</span></p>
                                                                </div>
                                                            </div>
                                                            <div class="content-card u-align-center">
                                                                <div class="content-card__content u-padding-none">
                                                                    <i class="${featureOverallCost.FeatureIcon} u-color-shade-light u-text-bold u-text-medium"></i>
                                                                    <br />
                                                                    <p class="u-text-smaller u-text-bold u-color-shade-light u-margin-bottom--none">
                                                                        ${featureOverallCost.FeatureTitle.toUpperCase()}
                                                                        <a href="#${featureOverallCost.FeatureName}-modal" class="button--plain-text" data-lity><i class="u-color-brand-b u-text-small icon-info-outline"></i></a>
                                                                    </p>
                                                                    <p class="u-color-brand-a u-text-largest u-text-bold"><span class="u-text-small">&#163;</span>${Number(featureOverallCost.FeatureValue).toFixed(2).split(".")[0]}<span class="u-text-small">.${Number(featureOverallCost.FeatureValue).toFixed(2).split(".")[1]}</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-grid u-align-center u-margin-bottom--none">
                                                            <div class="content-card u-align-center">
                                                                <div class="content-card__content u-padding-none">
                                                                    <i class="${featureSeatingCapacity.FeatureIcon} u-color-shade-light u-text-bold u-text-medium"></i>
                                                                    <p class="u-text-smaller u-text-bold u-color-shade-light u-margin-bottom--none">
                                                                        ${featureSeatingCapacity.FeatureTitle.toUpperCase()}
                                                                        <a href="#${featureSeatingCapacity.FeatureName}-modal" class="button--plain-text" data-lity><i class="u-color-brand-b u-text-small icon-info-outline"></i></a>
                                                                    </p>
                                                                    <p class="u-color-brand-a u-text-largest u-text-bold">
                                                                        ${featureSeatingCapacity.FeatureValue}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="content-card u-align-center">
                                                                <div class="content-card__content u-padding-none">
                                                                    <i class="${featureOutOfHours.FeatureIcon} u-color-shade-light u-text-bold u-text-medium"></i>
                                                                    <p class=" u-text-smaller u-text-bold u-color-shade-light u-margin-bottom--none">
                                                                        ${featureOutOfHours.FeatureTitle.toUpperCase()}
                                                                        <a href="#${featureOutOfHours.FeatureName}-modal" class="button--plain-text" data-lity><i class="u-color-brand-b u-text-small icon-info-outline"></i></a>
                                                                    </p>
                                                                    <p class="u-color-brand-a u-text-small u-text-bold">
                                                                        ${featureOutOfHours.FeatureValue}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="content-card u-align-center">
                                                                <div class="content-card__content u-padding-none">
                                                                    <i class="${featureAudioVisual.FeatureIcon} u-color-shade-light u-text-bold u-text-medium"></i>
                                                                    <p class="u-text-smaller u-text-bold u-color-shade-light u-margin-bottom--none">
                                                                        ${featureAudioVisual.FeatureTitle.toUpperCase()}
                                                                        <a href="#${featureAudioVisual.FeatureName}-modal" class="button--plain-text" data-lity><i class="u-color-brand-b u-text-small icon-info-outline"></i></a>
                                                                    </p>
                                                                    <p class="u-color-brand-a u-text-small u-text-bold">
                                                                        ${featureAudioVisual.FeatureValue}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>` : ``}
                            ${m.ctaLink1 ? '<a href="' + m.ctaLink1 + '" class="button button--icon-right button--plain-text" target="' + m.ctaTarget1 + '"' + m.ctaModal1 + '>' + m.ctaTitle1 + '</a>' : ''}
						</div>
					</div>
				`;

                const marker = new google.maps.Marker({
                    position: {
                        lat: m.lat,
                        lng: m.lng
                    },
                    title: m.title ? m.title : '',
                    icon: markerIcon,
                    label: markerStyle === 'numbered' ? markerLabel : null,
                    optimized: false
                });

                markers.push(marker);

                google.maps.event.addListener(map, 'click', function () {
                    markerInfowindow.close(map, marker);

                    if (markerStyle !== 'numbered') {
                        markers.map(m => {
                            m.setIcon({
                                url: icons.default.icon,
                                scaledSize: new google.maps.Size(40, 58)
                            });
                        });
                    }
                });

                marker.addListener('click', function () {
                    markerInfowindow.setContent(markerContent);
                    markerInfowindow.open(map, marker);

                    if (markerStyle !== 'numbered') {
                        markers.map(m => {
                            m.setIcon({
                                url: icons.default.icon,
                                scaledSize: new google.maps.Size(40, 58)
                            });
                        });
                        marker.setIcon({
                            url: icons.active.icon,
                            scaledSize: new google.maps.Size(50, 74)
                        });
                    }
                });

                marker.setMap(map);
            });
        }
    });
}
import $ from 'jquery'
import validate from 'jquery-validation'


export const loadingScreen = function (formElement, appendSelector) {
    const $loadingImage = $("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='64' height='64' fill='#5e7334'><path opacity='.25' d='M16 0a16 16 0 0 0 0 32 16 16 0 0 0 0-32m0 4a12 12 0 0 1 0 24 12 12 0 0 1 0-24'/><path d='M16 0a16 16 0 0 1 16 16h-4A12 12 0 0 0 16 4z'><animateTransform attributeName='transform' type='rotate' from='0 16 16' to='360 16 16' dur='0.8s' repeatCount='indefinite'/></path></svg>");
    $loadingImage.css({
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    });

    if (formElement) {
        let targetElement = formElement.querySelector(appendSelector);

        if (targetElement) {
            formElement.onsubmit = function (e) {
                if ($(formElement).valid()) {

                    //disabled submit buttons
                    formElement.querySelectorAll(('[type="submit"]')).forEach(function(el) {
                        el.onclick = function(e) {
                            e.preventDefault();
                            return false;
                        }
                    });

                    let overlay = document.createElement("div");
                    overlay.style.position = "absolute";
                    overlay.style.width = "100%";
                    overlay.style.height = "100%";
                    overlay.style.background = "rgba(255,255,255, 0.5";
                    overlay.append($loadingImage[0]);
                    overlay.style.top = 0;
                    overlay.style.right = 0;
                    overlay.style.zIndex = "49"; //CTA sticky on right side has 50

                    //make parent relative so overlay is contained
                    targetElement.style.position = "relative";

                    targetElement.insertBefore(overlay, targetElement.children[0]);
                }
            }
        }
    }

    return;
}

//other exports go here ex: "export const anotherFunctionYouCanGrab"

// Polyfills
require('objectFitPolyfill');
import 'nodelist-foreach-polyfill';
import 'element-closest';
import $ from 'jquery';

// Vendor
import rangeSlider from 'rangeslider-pure';
import 'awesomplete';
import ProgressBar from 'progressbar.js';

// Components
import './components/accordion';
import Carousel from './components/carousel';
import './components/nav';
import './components/charts';
import './components/basket';
import './components/copy';
import './components/sizes';
import './components/datepicker';
import './components/filters';
import './components/tabs';
import './components/maps';
import './components/modals';
import './components/multi-function-button';
import './components/price-calculator';
import './components/progress-bar';
import './components/scrollTo';
import './components/side-nav';
import './components/sticky-ctas';
import './components/switch';
import './components/tables';
import './components/team';
import './components/toggle';
import './components/top';
import './components/forms';
import './components/window';
import './utils/jquery.validate';
import Inputmask from "inputmask";

// Initialise
objectFitPolyfill();


// setup input masks
var maskedRefElements = document.querySelectorAll(".form__input--masked-ref");
if (maskedRefElements) {
    maskedRefElements.forEach(maskedRefElement => {
        Inputmask("99999-99999999", { "placeholder": "xxxxx-xxxxxxxx", greedy: true }).mask(maskedRefElement);
    });
}

var maskedDateElements = document.querySelectorAll(".form__input--date");
if (maskedDateElements) {
    maskedDateElements.forEach(maskedDateElement => {
        Inputmask("99/99/9999", { "placeholder": "dd/mm/yyyy", greedy: true }).mask(maskedDateElement);
    });
}

var maskedMoneyElements = document.querySelectorAll(".form__input--amount");
if (maskedMoneyElements) {
    maskedMoneyElements.forEach(maskedMoneyElement => {
        Inputmask({ alias: 'currency', prefix: "", digitsOptional: true, clearMaskOnLostFocus: false, rightAlign: false, allowMinus: false, removeMaskOnSubmit: true, max: 15000 }).mask(maskedMoneyElement);
    });
}

var maskedPhoneElements = document.querySelectorAll(".form_input--masked-tel");
if (maskedPhoneElements) {
    maskedPhoneElements.forEach(maskedPhoneElement => {
        Inputmask({ "mask": "9", "repeat": 11, "greedy": false }).mask(maskedPhoneElement);
    });
}

// Setup Carousels
var carouselElements = document.querySelectorAll(".carousel");

for (var i = 0; i < carouselElements.length; i++) {
    var carouselElement = carouselElements[i];
    var mobileItems = 1;

    if (carouselElement.classList.contains("carousel--comparison-table")) {
        mobileItems = 2;
    } else if (carouselElement.classList.contains("carousel--team")) {
        mobileItems = 2;
    } else if (carouselElement.classList.contains("carousel--overflow")) {
        mobileItems = 3;
    }

    var carousel = new Carousel({
        itemContainer: carouselElement.querySelector(".carousel__items"),
        nextElement: carouselElement.querySelector(".carousel__arrow--right") ? carouselElement.querySelector(".carousel__arrow--right") : null,
        prevElement: carouselElement.querySelector(".carousel__arrow--left") ? carouselElement.querySelector(".carousel__arrow--left") : null,
        dotsWrapper: carouselElement.querySelector(".carousel__dots") ? carouselElement.querySelector(".carousel__dots") : null,
        perPage: carouselElement.getAttribute("data-items-per-page") ? carouselElement.getAttribute("data-items-per-page") : 1,
        onTablet: carouselElement.getAttribute("data-items-at-tablet") ? carouselElement.getAttribute("data-items-at-tablet") : null,
        onMobile: mobileItems
    });
}

var cardGridCarousels = document.querySelectorAll(".card-grid--carousel");

if (window.innerWidth <= 500) {
    for (var i = 0; i < cardGridCarousels.length; i++) {
        var carouselElement = cardGridCarousels[i];
        var mobileItems = 1;

        var carousel = new Carousel({
            itemContainer: carouselElement,
            nextElement: carouselElement.querySelector(".card-grid__arrow--right") ? carouselElement.querySelector(".card-grid__arrow--right") : null,
            prevElement: carouselElement.querySelector(".card-grid__arrow--left") ? carouselElement.querySelector(".card-grid__arrow--left") : null,
            dotsWrapper: carouselElement.querySelector(".card-grid__dots") ? carouselElement.querySelector(".card-grid__dots") : null,
            perPage: carouselElement.getAttribute("data-items-per-page") ? carouselElement.getAttribute("data-items-per-page") : 1,
            onTablet: carouselElement.getAttribute("data-items-at-tablet") ? carouselElement.getAttribute("data-items-at-tablet") : null,
            onMobile: 1
        });
    }
}

// Range slider
const rangeSliders = document.querySelectorAll('.form__range')

if (rangeSliders) {
    rangeSliders.forEach(slider => {
        rangeSlider.create(slider, {
            onInit: () => {
                if (slider.dataset.tooltip !== 'false') {
                    const figure = document.createElement('span');
                    figure.classList.add('rangeSlider__tooltip');

                    if (slider.dataset.tooltip == 'bottom') {
                        figure.classList.add('rangeSlider__tooltip--bottom');
                    }

                    figure.innerHTML = slider.value
                    slider.parentNode.querySelector('.rangeSlider__handle').appendChild(figure);
                }
            },
            onSlide: (position, value) => {
                if (slider.dataset.tooltip != 'false') {
                    slider.parentNode.querySelector('.rangeSlider__handle span').innerHTML = slider.value;
                }
            }
        });
    });
}

const autoCompletes = document.querySelectorAll('.autocomplete');

if (autoCompletes.length > 0) {
    autoCompletes.forEach(input => {
        const listElement = input.parentNode;
        const locationText = input.dataset.locationText ? input.dataset.locationText : 'Use current location';
        const comboplete = new Awesomplete(input, {
            minChars: 0,
            // list can be array of strings, array of arrays/objects etc
            // refer to https://leaverou.github.io/awesomplete/
            list: [
                { label: 'Birmingham', value: 'Birmingham' },
                { label: 'Bilston', value: 'Bilston' },
                { label: 'Sutton Coldfield', value: 'Sutton Coldfield' },
                { label: 'Solihull', value: 'Solihull' }
            ]
        });
        const geolocationEnabled = true;

        if (geolocationEnabled) {
            const li = document.createElement('li');

            li.classList.add('form__input--location');
            li.innerHTML = `<a id="currentlocation" href="">${locationText}</a>`

            comboplete.ul.classList.add('awesomplete__list--geolocation-enabled')
            comboplete.ul.appendChild(li);

            input.addEventListener('focus', () => {
                if (comboplete.ul.childNodes.length === 0) {
                    comboplete.minChars = 0;
                    comboplete.evaluate();
                    comboplete.ul.appendChild(li);
                } else if (comboplete.ul.hasAttribute('hidden')) {
                    comboplete.ul.appendChild(li);
                    comboplete.open();
                } else {
                    comboplete.close();
                }
            });

            input.addEventListener('keyup', () => {
                if (comboplete.ul.childNodes.length === 0) {
                    comboplete.minChars = 0;
                    comboplete.evaluate();
                    comboplete.ul.appendChild(li);
                } else if (comboplete.ul.hasAttribute('hidden')) {
                    comboplete.open()
                    comboplete.ul.appendChild(li);
                } else {
                    comboplete.ul.appendChild(li);
                }
            });

            li.addEventListener('click', e => {
                e.preventDefault();

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(showPosition);
                } else {
                    $("#error").innerHTML("Geolocation is not supported by this browser.");
                }
            });
        }
    });
}

function showPosition(position) {
    $("#location").val(position.coords.latitude + "," + position.coords.longitude);
    comboplete.ul.attr("hidden", "true");
}

$.validator.setDefaults({
    ignore: ".ignore,:hidden:not([type=radio]):not([type=checkbox])",
    errorElement: "div",
    validClass: "form__input--success",
    errorClass: "dummy",
    onfocusout: function (element, something) {
        if ($(element).valid()) {
            $(element).removeClass("form__input--error").addClass("form__input--success");
        } else {
            $(element).addClass("form__input--error").removeClass("form__input--success");

        }
    },
    errorPlacement: function (error, element) {
        element.addClass("form__input--error");
        error.addClass("form__error");
        switch (element.parent().attr("class")) {
            case "form__radio form__radio--block":
                error.insertAfter(element.parents(".form__flex"));
                break;
            case "form__radio":
                error.insertAfter(element.parents(".form__fieldset__row--small-gap"));
                break;
            case "form__checkbox":
                error.insertAfter(element.parents(".form__checkbox"));
                break;
            case "form__field form__field--inline":
                error.insertAfter(element.parents(".form__fieldset__row"));
                break;
            default: error.insertAfter(element);
                break;
        }
    }
})

$(document.forms[0]).validate({
    invalidHandler: function (form, validator) {
        var errors = validator.numberOfInvalids();
        var target = $(validator.errorList[0].element).is(":visible") ? $(validator.errorList[0].element) : $(validator.errorList[0].element).parents(":visible").first();
        if (errors) {
            $("html, body").animate({
                scrollTop: target.offset().top - 100
            }, 750);
        }
    }

})

//as per https://dignityuk.eu.teamwork.com/#tasks/14218027
$('input').each(function () {
    var $field = $(this);
    if ($field) {
        $field.change(function () {
            var $characterLimit = $field.width() / parseFloat($field.css('font-size')) / 0.7;
            if ($characterLimit) {
                if ($field.val().length >= $characterLimit) {
                    $field.addClass('u-background-notick');
                }
                else {
                    $field.removeClass('u-background-notick');
                }
            }
        });
    }
})

$('#PrintButton').click(function () {
    var restorepage = $('body').html();
    var printcontent = $('#printable').clone();
    $('body').empty().html(printcontent);
    $('.noprint').hide();
    window.print();
    $('body').html(restorepage);
    $('.noprint').show();
})

var formNextButton = document.getElementById("Next");
var sendReceiptButton = document.getElementById("SendReceiptButton");

// Credits to HC
document.addEventListener('DOMContentLoaded', function () {
    if (formNextButton) {
        formNextButton.addEventListener("click", function (event) {
            if (!$(document.forms[0]).valid() || $(".form__input--error").length > 0) {
                return false;
            }
            else {
                $("#Next").addClass("waiting");
                $("#Next").prop("disabled", true);
                $("#Previous").prop("disabled", true);
                $("form").submit();
            }
        });

        formNextButton.addEventListener("submit", function () {
            if ($(document.forms[0]).valid() && $(".form__input--error").length == 0) {
                $("#Next").addClass("waiting");
                $("#Next").prop("disabled", true);
                $("#Previous").prop("disabled", true);
            }
        });
    }

    if (sendReceiptButton) {
        sendReceiptButton.addEventListener("click", function (event) {
            if (!$(document.forms[0]).valid() || $(".form__input--error").length > 0) {
                return false;
            }
            else {
                $("#SendReceiptButton").addClass("waiting");
                $("#SendReceiptButton").prop("disabled", true);
                $("form").submit();
            }
        });

        sendReceiptButton.addEventListener("submit", function () {
            if ($(document.forms[0]).valid() && $(".form__input--error").length == 0) {
                $("#SendReceiptButton").addClass("waiting");
                $("#SendReceiptButton").prop("disabled", true);
            }
        });
    }
});
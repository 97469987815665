// Vendor
import Siema from 'siema';

export default class Carousel {

	constructor(config) {
		if(config.prevElement && config.nextElement) {
			this.nextArrow = config.nextElement;
			this.prevArrow = config.prevElement;

			this.nextArrow.addEventListener("click", this.next.bind(this));
			this.prevArrow.addEventListener("click", this.prev.bind(this));
		}

		if(config.dotsWrapper) {
			this.dots = config.dotsWrapper;
		}

		this.slideCount = config.itemContainer.children.length;
		

		// Add the amount of dots needed from the first child of the dots container
		if(config.dotsWrapper) {
			for(let i = 1; i < this.slideCount; i++) {
				let newDot = this.dots.children.item(0).cloneNode();
				this.dots.appendChild(newDot);
				this.dots.children.item(i).addEventListener("click", (e) => {
					this.jump(i);
				});
			}

			// Add event listener to first dot
			this.dots.children.item(0).addEventListener("click", (e) => {
				this.jump(0);
			});
		}

		// Set the first dot to active
		this.currentSlide = 0;
		this.realCurrentSlide = this.currentSlide;

		if(config.dotsWrapper) {
			this.dots.children.item(this.currentSlide).classList.add("active");
		}
	
		// While the carousel has more per page than items that exist, duplicate all the children
		while(parseInt(config.perPage) >= config.itemContainer.children.length) {
			for(let i = 0; i < this.slideCount; i++) {
				let dupe = config.itemContainer.children.item(i).cloneNode(true);
				config.itemContainer.appendChild(dupe);
			}
		}

		this.realSlideCount = config.itemContainer.children.length;

		this.siemaInstance = new Siema({
			selector: config.itemContainer,
			loop: true,
			duration: 500,
			easing: 'ease-in-out',
			perPage: {
				0: config.onMobile,
				760: config.onTablet ? parseInt(config.onTablet) : parseInt(config.perPage),
				960: parseInt(config.perPage)
			},
			onChange: () => { this.onChange.bind(this)(config); }
		});
	}

	onChange(config) {
		this.realCurrentSlide = this.siemaInstance.currentSlide < 0 ? this.siemaInstance.currentSlide + this.realSlideCount : this.siemaInstance.currentSlide;
		
		if(config.dotsWrapper) {
			this.dots.children.item(this.currentSlide).classList.remove("active");
			this.currentSlide = (this.dots.children.length + this.siemaInstance.currentSlide) % this.slideCount;
			this.dots.children.item(this.currentSlide).classList.add("active");
		}
	}

	jump(i) {
		// Need to do it this way because of the potential of duplicated slides
		var distance = i - this.currentSlide;

		for(let a = 0; a < Math.abs(distance); a++) {
			if(distance < 0) {
				this.siemaInstance.prev();
			} else {
				this.siemaInstance.next();
			}
		}
	}

	next(e) {
		this.siemaInstance.next();
	}

	prev(e) {
		this.siemaInstance.prev();
	}

}
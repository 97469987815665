const forms = document.querySelectorAll('.form');
const multiselectElements = document.querySelectorAll('.form__multi-select')
const multiselectActiveClass = 'form__multi-select--open'

if(multiselectElements.length > 0) {
	multiselectElements.forEach(filter => {
		if(filter) {
			filter.addEventListener('click', () => {
				filter.classList.toggle(multiselectActiveClass)
			})

			document.addEventListener('click', function(e) {
				const isClickInside = filter.contains(e.target)
		
				if (!isClickInside && filter.classList.contains(multiselectActiveClass)) {
					filter.classList.toggle(multiselectActiveClass)
				}
			})
		}
	})
}

forms.forEach(form => {

	const checkboxBlocks = form.querySelectorAll(".form__checkbox--block");

	checkboxBlocks.forEach(checkboxBlock => {
		const checkboxBlockInput = checkboxBlock.querySelector("input");

		// If the checkboxBlock is actually a radio input underneath
		if(checkboxBlockInput.type == "radio") {
			// On page load, (i.e. if it was populated by the server), add the "other selected" class to siblings
			if(checkboxBlockInput && checkboxBlockInput.checked) {
				addClassToSiblings(checkboxBlock, ".form__checkbox--block", "form__checkbox--other-selected")
			}
			
			if(checkboxBlockInput) {
				// When this input changes
				checkboxBlockInput.addEventListener("change", e => {
					// If it's become checked, add other to siblings
					if(e.currentTarget.checked) {
						removeClassFromChildren(checkboxBlock, ".form__checkbox--block", "form__checkbox--other-selected");
						addClassToSiblings(checkboxBlock, ".form__checkbox--block", "form__checkbox--other-selected")
					} else {
						removeClassFromChildren(checkboxBlock, ".form__checkbox--block", "form__checkbox--other-selected");
					}
				});
			}
		}
	})

});

function removeClassFromChildren(elem, selector, className) {
	// Get direct siblings
	const siblings = elem.parentNode.querySelectorAll(selector);
	console.log(siblings);
	siblings.forEach(sibling => {
		sibling.classList.remove(className)
	});
}

function addClassToSiblings(elem, selector, className) {
	// Get direct siblings
	const siblings = elem.parentNode.querySelectorAll(selector);

	siblings.forEach(sibling => {
		sibling.classList.add(className)
	});

	elem.classList.remove(className);
}
import { loadingScreen } from './utils/forms';

var $ = require('./utils/additional-methods.js');
// toggle email fields on and off
// ******************************

$(toggleEmailFields());

var toggleInput = document.getElementById("toggleEmailFields");

if (toggleInput) {
    toggleEmailFields();
    toggleInput.checked = localStorage.getItem("showEmailFields") === "true";


    toggleInput.onchange = (e) => {
        toggleEmailFields(e.target.checked);
    };
}

$("#InvoiceReference").rules("add", {
    pattern: /^\d{5}[-]?\d{8}[-]?[A-Za-z0-9]?$/,
    messages: {
        pattern: "Please enter a Valid invoice reference."
    }
})

$("#Date").rules("add", {
    pattern: /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((18|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((18|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/,
    messages: {
        pattern: "Please enter a valid date"
    }
})

$(document.forms[0]).validate();


function toggleEmailFields(toggle) {
    if (toggle != null) {
        localStorage.setItem("showEmailFields", toggle);
    }

    var showEmailFields = localStorage.getItem("showEmailFields");

    if (showEmailFields === "true") {
        $('#divWithEmailFields').fadeIn();
        $('.email-field').each(function () {
            $(this).change(function () {
                $characterLimit = $(this).width() / parseFloat($(this).css('font-size')) / 0.7;
                if ($(this).val().length >= $characterLimit) {
                    $(this).addClass('notick');
                }
                else {
                    $(this).removeClass('notick');
                }
            });
        });
    }
    else {
        $('#divWithEmailFields').hide();
        $('.email-field').each(function () {
            $(this).val('');
            $(this).removeClass('form__input--error');
            $(this).removeClass('form__input--success');
        });
    }
};

function printContent(el) {
    //dirty fix for godaddy seal until better solution is found. see backlog item #14307910
    $("#siteseal").find("script").remove();

    var restorepage = $('body').html();
    var printcontent = $('#' + el).clone();
    $('body').empty().html(printcontent);
    $('.noprint').hide();
    window.print();
    $('body').html(restorepage);
    $('.noprint').show();
}

window.printContent = printContent;

// toggle address fields on and off
// ********************************

$(toggleAddressFields());

function toggleAddressFields(toggle) {
    if (toggle != null) {
        localStorage.setItem("showAddressFields", toggle);
    }

    var showAddressFields = localStorage.getItem("showAddressFields");

    if (showAddressFields == "true") {
        $('#divWithoutAddressFields').hide();
        $('#divWithAddressFields').fadeIn();
        $('#btnFetchAddresses').hide();
        $('#AddressType').val("");
    }
    else {
        $('#divWithoutAddressFields').fadeIn();
        $('#divWithAddressFields').hide();
        $('#btnFetchAddresses').fadeIn();
        $('#AddressType').val("auto");
    }
};

$('#btnTurnOnAddressFields').click(function () {
    toggleAddressFields(true);
    return false;
});

$('#btnTurnOffAddressFields').click(function () {
    toggleAddressFields(false);
    return false;
});

loadingScreen(document.querySelector(".addLoadingIndicator"), ".checkout__body");

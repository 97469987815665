const accordions = document.querySelectorAll('.accordion')

if(accordions) {
	accordions.forEach(accordion => {
		const accordionItems = accordion.querySelectorAll('.accordion__item')

		accordionItems.forEach(accordionItem => {
			const accordionTrigger = accordionItem.querySelector('.accordion__title')

			accordionTrigger.addEventListener('click', () => {
				if(accordion.classList.contains("accordion--single-view")) {
					accordionItem.classList.toggle('accordion__item--open')
					accordionItems.forEach(accordionItem2 => accordionItem !== accordionItem2 ? accordionItem2.classList.remove("accordion__item--open") : null);
				} else {
					accordionItem.classList.toggle('accordion__item--open')
				}
				
			})
		})
	})
}

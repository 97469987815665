const priceElements = document.querySelectorAll('.calculator__price')
const priceRange = document.getElementById('price-range')
const planPrice = '3495' // replace with selected plan price/interest calculations etc
const paymentsElements = document.querySelectorAll('.calculator__no-payments')
const finalPaymentElements = document.querySelectorAll('.calculator__final-payment')
const totalPaymentElements = document.querySelectorAll('.calculator__total')

if(priceElements.length > 0) {
	calculatePrice((planPrice / priceRange.value).toFixed(2))
	calculatePayments(priceRange.value)
	calculateFinalPayment(200)
	calculateTotalPayment()

	priceRange.addEventListener('change', () => {
		calculatePrice((planPrice / priceRange.value).toFixed(2))
		calculatePayments(priceRange.value)
		calculateFinalPayment(200)
		calculateTotalPayment()
	})
}

function calculatePrice(input) {
	priceElements.forEach(priceElement => {
		const splitInt = input.split('.')

		priceElement.innerHTML = `${splitInt[0]}.<sub>${splitInt[1] ? splitInt[1] : '00'}`
	})
}

function calculatePayments(input) {
	paymentsElements.forEach(paymentElement => {
		paymentElement.innerHTML = input
	})
}

function calculateFinalPayment(input) {
	// replace with real calculation
	finalPaymentElements.forEach(finalPaymentElement => {
		finalPaymentElement.innerHTML = input
	})
}

function calculateTotalPayment() {
	// replace with real calculation
	totalPaymentElements.forEach(totalPaymentElement => {
		const splitInt = planPrice.split('.')

		totalPaymentElement.innerHTML = `${splitInt[0]}.<sub>${splitInt[1] ? splitInt[1] : '00'}`
	})
}

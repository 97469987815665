const tabs = document.querySelectorAll(".tabs");

// [].slice.call converts a NodeList to an array
if(tabs) {
	tabs.forEach(function(tab, i) {
		var tabTriggersParent = tab.querySelector(".tabs__triggers");
		var tabTriggers = [].slice.call(tabTriggersParent.querySelectorAll(".tabs__trigger"));

		var tabContentsParent = tab.querySelector(".tabs__content");
		var tabContents = [].slice.call(tabContentsParent.querySelectorAll(".tab"));

		// Filter out tab contents so we only get direct children
		tabContents = tabContents.filter((tabContent, i) => { return tabContent.parentNode == tabContentsParent });

		// Set the first in any tabset to be active
		tabTriggers.forEach((tabTrigger) => { tabTrigger.classList.remove("tabs__trigger--active"); });
		tabContents.forEach((tabContent) => { tabContent.classList.remove("tab--active"); });
		tabTriggers[0].classList.add("tabs__trigger--active");
		tabContents[0].classList.add("tab--active");

		// On click of any tab trigger...
		tabTriggers.forEach((tabTrigger, i) => {
			tabTrigger.addEventListener("click", () => {

				// Remove classes from other tab triggers and contents so they're not active
				tabTriggers.forEach((tabTrigger) => { tabTrigger.classList.remove("tabs__trigger--active"); });
				tabContents.forEach((tabContent) => { tabContent.classList.remove("tab--active"); });

				// Make the trigger we clicked, and its corresponding contents active
				tabTrigger.classList.add("tabs__trigger--active");
				tabContents[i].classList.add("tab--active");

			})
		});

		// Tab mobile dropdown
		var tabsMobileDropdownButton = tabTriggersParent.querySelector(".tabs__mobile-dropdown-button");
		var tabsMobileDropdown = tabTriggersParent.querySelector(".tabs__mobile-dropdown");
		var tabsMobileDropdownItems = tabTriggersParent.querySelectorAll(".tabs__mobile-dropdown-item");

		if(tabsMobileDropdownButton && tabsMobileDropdown) {
			tabsMobileDropdownButton.addEventListener("click", (e) => {
				tabsMobileDropdown.classList.toggle("tabs__mobile-dropdown--show");
				tabsMobileDropdownButton.classList.toggle("tabs__mobile-dropdown-button--close");
			});
		}

		if(tabsMobileDropdownItems) {
			tabsMobileDropdownItems.forEach((item, i) => {
				item.addEventListener("click", (e) => {
					// Remove classes from other tab triggers and contents so they're not active
					tabTriggers.forEach((tabTrigger) => { tabTrigger.classList.remove("tabs__trigger--active"); });
					tabContents.forEach((tabContent) => { tabContent.classList.remove("tab--active"); });

					// Make the trigger we clicked, and its corresponding contents active
					tabTriggers[i].classList.add("tabs__trigger--active");
					tabContents[i].classList.add("tab--active");

					tabsMobileDropdown.classList.remove("tabs__mobile-dropdown--show");
					tabsMobileDropdownButton.classList.remove("tabs__mobile-dropdown-button--close");
				})
			});
		}
	});
}
